import React, { forwardRef, useImperativeHandle, useState, useEffect} from "react";
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Draggable from 'react-draggable';
import modals from "../data/modals.json";
import useSound from 'use-sound';
import click01 from '/sound/click01.wav';
import click02 from '/sound/click02.wav';

import error02 from '/sound/error02.wav';
import error03 from '/sound/error03.wav';
import error04 from '/sound/error04.wav';



const Modal = forwardRef((props, ref) => {

  const [playClick01] = useSound(click01);
  const [playClick02] = useSound(click02);


  const [playError02] = useSound(error02);
  const [playError03] = useSound(error03);
  const [playError04] = useSound(error04);





  useImperativeHandle(ref, () => ({
   show() {
        showF()
    },
  hide() {
    hideF()
  }
}));



useEffect(() => {
  const handleResize = () => {
    const modalRect =  document.querySelector("#modal" + props.id + " .modal-content")


    var  stringN = "#root"
    if(document.querySelector("#root .r3f")){
  stringN =  "#root .r3f"
  } 
  
  const rootRect =  document.querySelector(stringN).getBoundingClientRect()
  
    const boundLN = rootRect.left - (rootRect.width/2) + (modalRect.scrollWidth/2) + 1
    const boundTN =  rootRect.top - (rootRect.height/2)  + (modalRect.scrollHeight/2)  + 1
    const boundRN =  rootRect.right - (rootRect.width/2)- (modalRect.scrollWidth/2)  - 1
    const boundBN =  rootRect.bottom - (rootRect.height/2) - (modalRect.scrollHeight/2)  - 1
    
      setState({  ...state , boundL:boundLN,boundT:boundTN,boundR:boundRN,boundB:boundBN });
  
  };

 window.addEventListener("resize", handleResize);
 if (props.show== true) {

  showF()

}

  return () => {
      window.removeEventListener("resize", handleResize);
  };
}, []);



const [state, setState] = useState( {
  draggerStart: null,
  boundL: null,
  boundT: null,
  boundR: null,
  boundB: null,
})






  const draggerRef = React.createRef();
  const  modal = modals.filter((v, k) => {
    if (v.ref == props.modal) {
      return v;
    }
  })[0]



 


    const  updateSize = () =>  {

  const modalRect =  document.querySelector("#modal" + props.id + " .modal-content")
  var  stringN = "#root"
  if(document.querySelector("#root .r3f")){
stringN =  "#root .r3f"
} 

const rootRect =  document.querySelector(stringN).getBoundingClientRect()



 

    const boundLN = rootRect.left - (rootRect.width/2) + (modalRect.scrollWidth/2) + 1
    const boundTN =  rootRect.top - (rootRect.height/2)  + (modalRect.scrollHeight/2)  + 1
    const boundRN =  rootRect.right - (rootRect.width/2)- (modalRect.scrollWidth/2)  - 1
    const boundBN =  rootRect.bottom - (rootRect.height/2) - (modalRect.scrollHeight/2)  - 1
  

  

    if(modal.position == "random"){

      modal.positionx =  boundLN  + (Math.random() * (boundRN - boundLN  ));
      modal.positiony =  boundBN + (Math.random() * (boundTN - boundBN));
    }
    

    

    draggerRef.current.state.x = modal.positionx
    draggerRef.current.state.y = modal.positiony

  


   setState({   draggerStart: { x: modal.positionx, y: modal.positiony }, boundL:boundLN,boundT:boundTN,boundR:boundRN,boundB:boundBN  })

  }


  const  startDrag = () => {

   setState({  ...state, draggerStart: null })
  }




  const showF = () => {


    var items = document.querySelectorAll('.modal');

   items.forEach((item) => {
     item.classList.remove("ontop")
    });
    
    document.querySelector('#modal'+ props.id).classList.add("ontop");
    var modalObject = bootstrap.Modal.getOrCreateInstance('#modal'+ props.id , {
      backdrop: modal.backdrop
    });


    modalObject.show();

    updateSize()

    if (modal.sound == 1) {

      playError01()
    
    }else if (modal.sound == 2) {
    
      playError02()
    
    }else if (modal.sound == 3) {
    
      playError03()
    
    }else if (modal.sound == 4) {
    
      playError04()
    
    }
    
  }


  const  hideF = () => {
 
    var modalObject= bootstrap.Modal.getOrCreateInstance('#modal'+ props.id , {
      backdrop: modal.backdrop
    });
    modalObject.hide();
  }

  return (
    <div
    id={"modal" + props.id}
    className="modal fade ontop"
    tabIndex="-1"
    role="dialog"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
 
      <div className="modal-dialog modal-dialog-centered">
        <Draggable handle=".modal-header"
          defaultPosition={{ x: modal.positionx, y: modal.positiony }}
          bounds={{left:state.boundL,top:state.boundT,right:state.boundR,bottom:state.boundB}}
          position={state.draggerStart}
          ref={draggerRef}
          onStart={startDrag}
        >
          <div className="modal-content">
        
            <div className="modal-header" >
              <h5 className="modal-title">{modal.title}</h5>
        
              {modal.closeButton ? 
              
           <button type="button" className="btn-close"  onClick={() => {props.cancelF?.(); playClick02();hideF()}}  onTouchEnd={() => {props.cancelF?.(); playClick02();hideF()}} aria-label="Close"  ></button>
          : null} 

       



            </div>
            <div className="modal-body d-flex justify-center align-content-center align-items-center">
     

  


              <img className="icon" src={"/images/"+modal.icon + ".png"} />
              <div>{modal.text}</div>
            </div>
            <div className="modal-footer">

     
            {modal.cancel ? 
            <button className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-hidden="true"
                onClick={() => {props.cancelF?.(); playClick02()}}
              >
               {modal.cancel}  
              </button>
          : null} 



              {modal.continue ? 
              <button className={"btn btn-" + modal.iconcolor}
                data-bs-dismiss="modal"
                aria-hidden="true"
            
                onClick={() => {props.continueF?.(); playClick01() }}
              >
             {modal.continue}  
              </button>

              : null} 

</div>
       
          </div>
        </Draggable>
      </div>
    </div>

  );
});

export default Modal;
