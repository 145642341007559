import {
  Html,
  useGLTF,
  MeshDistortMaterial,
  useTexture,
  MeshReflectorMaterial,
  useAnimations,Float
} from "@react-three/drei";
useGLTF.setDecoderPath("../libs/draco/");
import * as THREE from "three";
import { useRef, useEffect } from "react";
import Modal from "../components/Modal.jsx";
import { useStore } from "../components/GameStore.jsx";
import CameraControlsBox from "../components/CameraControlsBox01.jsx";
import Postprocessing from "../components/Postprocessing01.jsx";



export default function TitleScene(props) {
  const monster = useRef();
  const group = useRef();

  const modalRef = useRef();

  const texture = useTexture("../models/TitleScene.png");
  texture.colorSpace = THREE.SRGBColorSpace
  texture.flipY = false;
  texture.channel = 1;

  const material = new THREE.MeshStandardMaterial();
  material.transparent = true;
  material.map = texture;
  material.side = THREE.DoubleSide;
  material.alphaTest = 0.5;

  const { nodes, materials, animations } = useGLTF("../models/TitleScene.glb");
  const { actions, mixer  } = useAnimations(animations, group);

  useEffect(() => {
    
      actions.fiorearm1Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm2Action.setLoop(THREE.LoopPingPong);


      actions.playAction.setLoop(THREE.LoopPingPong);
      actions.playAction.repetitions = 2;

      actions.legsAction.setLoop(THREE.LoopPingPong);
      actions.legsAction.repetitions = 2;

      actions.dance.setLoop(THREE.LoopOnce);
      actions.dance.clampWhenFinished= true;


      // if (!actions?.dance.isRunning()) {
      //   actions?.dance.reset().play();
      // }


      if (!actions?.fiorearm1Action.isRunning()) {
        actions?.fiorearm1Action.reset().play();
      }
      if (!actions?.fiorearm2Action.isRunning()) {
        actions?.fiorearm2Action.reset().play();
      }

  
  }, [mixer]);

  const playAnimation = (e) => {
    if (!actions?.playAction.isRunning()) {
      actions?.playAction.reset().play();
    }
    if (!actions?.legsAction.isRunning()) {
      actions?.legsAction.reset().play();
    }
  };
  const playDance = (e) => {
    if (!actions?.dance.isRunning()) {
      actions?.dance.reset()
      .crossFadeFrom(actions?.standing, 0.2, false)
      .play();
    }
 
  };


  mixer.addEventListener("finished", function (e) {
    if (e.action._clip.name == "dance" ) {
    
      actions?.standing
        .reset()
        .crossFadeFrom(actions?.dance, 0.2, true)
        .play();

   
    }
 
  
  
  });


  const startGame = () => {
    useStore.setState({ scene: "UpdateScene" });
  };

  const openModal = () => {
    modalRef.current.show();
  };

  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">

 
          <group name="Cup"
              position={[
                nodes.Cup.position.x,
                nodes.Cup.position.y,
                nodes.Cup.position.z,
              ]}
              rotation={[
                nodes.Cup.rotation.x,
                nodes.Cup.rotation.y,
                nodes.Cup.rotation.z,
              ]}
          
              scale={[
                nodes.Cup.scale.x,
                nodes.Cup.scale.y,
                nodes.Cup.scale.z,
              ]}
          
              onPointerOver={playDance}
          >
            <skinnedMesh
              name="body"
              geometry={nodes.body.geometry}
              material={material}
              skeleton={nodes.body.skeleton}
            />
            <skinnedMesh
              name="eyel"
              geometry={nodes.eyel.geometry}
              material={material}
              skeleton={nodes.eyel.skeleton}
            />
            <skinnedMesh
              name="eyer"
              geometry={nodes.eyer.geometry}
              material={material}
              skeleton={nodes.eyer.skeleton}
            />
            <skinnedMesh
              name="hair"
              geometry={nodes.hair.geometry}
              material={material}
              skeleton={nodes.hair.skeleton}
            />
            <skinnedMesh
              name="limbs"
              geometry={nodes.limbs.geometry}
              material={material}
              skeleton={nodes.limbs.skeleton}
            />
            <skinnedMesh
              name="mouth"
              geometry={nodes.mouth.geometry}
              material={material}
              skeleton={nodes.mouth.skeleton}
            />
            <primitive object={nodes.mixamorigHips} />
          </group>
    


        <mesh
          name="pianta6"

          geometry={nodes.pianta6.geometry}
          material={material}

          position={[
            nodes.pianta6.position.x,
            nodes.pianta6.position.y,
            nodes.pianta6.position.z,
          ]}
          rotation={[
            nodes.pianta6.rotation.x,
            nodes.pianta6.rotation.y,
            nodes.pianta6.rotation.z,
          ]}

        />
<Float
  speed={0.5} // Animation speed, defaults to 1
  rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
  floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
  floatingRange={[0,0.7]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
>
 
          <mesh
            geometry={nodes.monster.geometry}
            ref={monster}
            position={[
              nodes.monster.position.x,
              nodes.monster.position.y,
              nodes.monster.position.z,
            ]}
          >
            <MeshDistortMaterial
              map={texture}
              metalness={0.6}
              roughness={0.6} 

              distort={0.6} speed={1}
            />
          </mesh>
</Float>
          <mesh
            geometry={nodes.boenk.geometry}
            position={[
              nodes.boenk.position.x,
              nodes.boenk.position.y,
              nodes.boenk.position.z,
            ]}
            material={material}
          />

          <group name="legs"
                position={[
                  nodes.legs.position.x,
                  nodes.legs.position.y,
                  nodes.legs.position.z,
                ]}
          
          >
            <skinnedMesh
              name="legsg"
              geometry={nodes.legsg.geometry}
              material={material}
              skeleton={nodes.legsg.skeleton}
        
            />
            <primitive object={nodes.bone03} />
            <primitive object={nodes.ikbone02} />
            <primitive object={nodes.bone01} />
            <primitive object={nodes.ikbone01} />
          </group>
          <group name="playcontainer"
             position={[
              nodes.playcontainer.position.x,
              nodes.playcontainer.position.y,
              nodes.playcontainer.position.z,
            ]}
          >
            <mesh
              name="play"
           
              geometry={nodes.play.geometry}
              material={material}
              onPointerEnter={() => {
                document.body.style.cursor = "url('/images/cursor.png'), pointer";
              }}
              onPointerLeave={() => {
                document.body.style.cursor = "default";
              }}
              onClick={openModal}
              onPointerOver={playAnimation}
            />
          </group>

          <group name="fiorearm1">
            <skinnedMesh
              name="fiore1"
              geometry={nodes.fiore1.geometry}
              material={material}
              skeleton={nodes.fiore1.skeleton}
              position={[
                nodes.fiore1.position.x,
                nodes.fiore1.position.y,
                nodes.fiore1.position.z
              ]}
            />
            <primitive object={nodes.Body5} />
          </group>

        <group name="fiorearm2">
            <skinnedMesh
              name="fiore2"
              geometry={nodes.fiore2.geometry}
              material={material}
              skeleton={nodes.fiore2.skeleton}
              position={[
                nodes.fiore2.position.x,
                nodes.fiore2.position.y,
                nodes.fiore2.position.z
              ]}
            />
            <primitive object={nodes.Body5_1} />
          </group> 
       
        </group>
      </group>

      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1.8, -7]}>
        <circleGeometry args={[7, 50]} />
        <MeshReflectorMaterial
          blur={[300, 300]}
          resolution={1024}
          mixBlur={1}
          mixStrength={1}
          depthScale={2}
          minDepthThreshold={0.4}
          color="#555"
          metalness={0.8}
          roughness={1}
          
        />
      </mesh>
      <Html wrapperClass="fullscreen">
        <Modal
          id="m1"
          ref={modalRef}
          modal={"Important Update"}
          continueF={startGame}
        />{" "}
      </Html>

      <Postprocessing />
      <CameraControlsBox />
    </>
  );
}
