import { Navbar, Product, Footer } from "../components";

function Shop() {
  return (
    <>
      <Navbar />
     
      <Product />
      <Footer />
    </>
  )
}

export default Shop