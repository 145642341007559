
import { useStore } from "../../../../components/GameStore.jsx";



  const cart = []

const handleCart = (state=cart, action) =>{
    const product = action.payload
    let returnStore =[]
    switch(action.type){
        case "ADDITEM":
           
            // Check if product already in cart
            const exist = state.find((x) => x.id === product.id)
            if(exist){
                // Increase the quantity
                returnStore= state.map((x)=>x.id ===product.id?{...x, qty: x.qty+1}:x)
            }
            else{
                returnStore= [...state, {...product, qty:1}]
            }

     
            useStore.setState({ cart: returnStore });
            return returnStore
            break;
        case "DELITEM":
         
            const exist2 = state.find((x) => x.id === product.id)
            if(exist2.qty === 1){

                returnStore=state.filter((x)=>x.id!==exist2.id)
            }
            else{
                
                returnStore= state.map((x)=> x.id===product.id?{...x, qty:x.qty-1}:x)
            }

            useStore.setState({ cart: returnStore });
            return returnStore
            break;

            case "LOAD":

            state= useStore((state) => state.cart);
            return state
            break;

            case "RESET":

            state= []

            useStore.setState({ cart:  state});
            
            return state
            break;

        default:
      
            return state
            break;
    }
}

export default handleCart