
    //import {  useRef } from 'react'
  // import * as THREE from 'three'
  // import { useControls } from "leva";

import {
    // Plane,
    // Html,
    // OrbitControls,
    // PresentationControls,
    // Bounds,
    // Image,
    // Float,
    // useGLTF,
    // Billboard,
    // Text,
    // AccumulativeShadows,
    // RandomizedLight,
    // Environment,
    useVideoTexture,
    // SoftShadows,useHelper
    
  } from "@react-three/drei"
export default function VideoBackground(props) {


     // const light = useRef()
   // useHelper(light, THREE.SpotLightHelper, 'cyan')
    const texture = useVideoTexture("/videos/caustics.mp4",{
      unsuspend: 'loadedmetadata',
      crossOrigin: 'Anonymous',
      muted: true,
      loop: true,
      start: true
    });
    return <spotLight  
    

  decay={0} 
    map={texture} {...props} 
   shadow-mapSize-height={512}
  shadow-mapSize-width={512}
    />;
}