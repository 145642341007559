import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="pb-5 text-center container">
        <div className="d-flex align-items-center justify-content-center pb-5 row">
          <div className="col-12">
    
          <div className="display-4  text-primary">Shop with confidence in our online store!</div>
          <img className="my-2" src="/images/shop/hr.gif" width={"600px"} height={"auto"}/>
          <div className="display-6 mb-3 text-success outline">With a wide range of payment options and quality certifications, we guarantee you a smooth and secure shopping experience.</div>
          <div className="mb-3"><img src="/images/shop/cards.png" width={"400px"} height={"auto"}/></div>
          <span className="rainbow d-inline-block p-1"><img src="/images/shop/certificate.png" width={"70px"} height={"auto"}/>   </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
