import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Navbar = () => {
    const state = useSelector(state => state.handleCart)
    return (
        <nav className="pt-2 px-md-1 position-fixed w-100 start-0 top-0 nav__shop">
            <div className="container-fluid d-flex justify-content-between align-items-center">
            <a href='/shop'><img src="/images/shop/logo.png" width={"90px"} height={"90px"}/></a>
            <NavLink to="/shop/cart" className="btn btn-dark btn-lg mx-2"><i className="fa fa-cart-shopping mr-1"></i> Cart ({state.length}) </NavLink>
            </div>
        </nav>
    )
}

export default Navbar