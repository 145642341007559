import {
    EffectComposer,
    Bloom,
    HueSaturation,
    BrightnessContrast,
    TiltShift2,TiltShift,
    WaterEffect,
    ToneMapping,  DepthOfField,
    Pixelation,
    Noise,
    Vignette,SSAO
  } from "@react-three/postprocessing";
  import { BlendFunction } from 'postprocessing'
  import VideoBackground from "../components/VideoBackground.jsx";
  import { Preload, AdaptiveDpr, Bvh, Billboard, Environment, AdaptiveEvents } from "@react-three/drei";
export default function Postprocessing03() {

    return (
      <>

<ambientLight intensity={2} />
  
         <Billboard>
            <VideoBackground
              distance={100}
              intensity={5}
              angle={0.3}
              penumbra={1}
              position={[0, 0, 20]}
            />
          </Billboard> 
         <EffectComposer depthBuffer enableNormalPass> 
    {/*    <EffectComposer disableNormalPass>  */}
{/*         <SSAO
    blendFunction={BlendFunction.MULTIPLY} // blend mode
    samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
    rings={4} // amount of rings in the occlusion sampling pattern
    distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
    distanceFalloff={0.0} // distance falloff. min: 0, max: 1
    rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
    rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
    luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
    radius={20} // occlusion sampling radius
    scale={0.5} // scale of the ambient occlusion
    bias={0.5} // occlusion bias
  />  */}
 <Bloom mipmapBlur luminanceThreshold={0.8} intensity={1} />    


         <ToneMapping
    blendFunction={BlendFunction.NORMAL} // blend mode
    adaptive={true} // toggle adaptive luminance map usage
    resolution={256} // texture resolution of the luminance map
    middleGrey={0.8} // middle grey factor
    maxLuminance={20.0} // maximum luminance
    averageLuminance={1.0} // average luminance
    adaptationRate={1.0} // luminance adaptation rate
  />

        {/* <BrightnessContrast brightness={0} contrast={0.2} />      
    */}

         


         <Noise opacity={0.05} />  
         <Vignette eskil={false} offset={0.1} darkness={1.1} /> 
         </EffectComposer> 
        </>
      );
    
}