



import {
  Html,
    useGLTF,
    Float,
    MeshReflectorMaterial,

  useTexture,

  useAnimations
} from "@react-three/drei";
useGLTF.setDecoderPath("../libs/draco/");
import * as THREE from "three";
import { useRef, useEffect } from "react";



  import  Modal  from "../components/Modal.jsx";

  import useSound from 'use-sound';
  
  import CameraControlsBox from "../components/CameraControlsBox04.jsx";
  import Postprocessing from "../components/Postprocessing04.jsx";
  import { useStore } from "../components/GameStore.jsx";
  import background from '/sound/psi.wav';

export default function TripScene(props) {
  const [playBackground, {stop }] = useSound(background, {
    loop:true, volume:1, interrupt:true
    });

playBackground()
const modalRefFinal = useRef();

const reset = useStore((state) => state.reset);
const resetAll = () => {
  reset();


};
  const group = useRef();
  const camera = useRef();





useEffect(() => {

  return () => {
    stop();
  };
}, [stop]);



useEffect(() => {


 setTimeout(() => {
   modalRefFinal?.current.show()
}, 10000);


   return () => {
  
   };
 }, [ modalRefFinal]);


  const texture = useTexture("../models/TripScene.png");
texture.colorSpace = THREE.SRGBColorSpace
  texture.flipY = false;
  texture.channel = 1;

  const material = new THREE.MeshStandardMaterial();
  material.transparent = true;
  material.map = texture;
 material.side = THREE.DoubleSide;
  material.alphaTest = 0.5;


  const { nodes, materials, animations } = useGLTF("../models/TripScene.glb");
  const { actions, mixer  } = useAnimations(animations, group);

  useEffect(() => {
    
      actions.fiorearm1Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm2Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm3Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm4Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm5Action.setLoop(THREE.LoopPingPong);
      actions.fiorearm6Action.setLoop(THREE.LoopPingPong);
   
      actions.fly.setLoop(THREE.LoopPingPong);
      if (!actions?.fly.isRunning()) {
        actions?.fly.reset().play();
      }

      if (!actions?.fiorearm1Action.isRunning()) {
        actions?.fiorearm1Action.reset().play();
      }
      if (!actions?.fiorearm2Action.isRunning()) {
        actions?.fiorearm2Action.reset().play();
      }
      if (!actions?.fiorearm3Action.isRunning()) {
        actions?.fiorearm3Action.reset().play();
      }
      if (!actions?.fiorearm4Action.isRunning()) {
        actions?.fiorearm4Action.reset().play();
      }
      if (!actions?.fiorearm5Action.isRunning()) {
        actions?.fiorearm5Action.reset().play();
      }
      if (!actions?.fiorearm6Action.isRunning()) {
        actions?.fiorearm6Action.reset().play();
      }
  
  }, [mixer]);








  return (
    <>


      <group ref={group} {...props} dispose={null} rotation-x={0.4}>
        <group name="Scene">
        <Float
  speed={0.5}
  rotationIntensity={1} 
  floatIntensity={1} 
 // floatingRange={[0, 0.1]}
>

<group name="CupContainer" position={[ nodes.CupContainer.position.x, nodes.CupContainer.position.y, nodes.CupContainer.position.z]} rotation={[nodes.CupContainer.rotation.x, nodes.CupContainer.rotation.y, nodes.CupContainer.rotation.z]} >
          <group name="Cup">
            <skinnedMesh
              name="body"
              geometry={nodes.body.geometry}
              material={material}
              skeleton={nodes.body.skeleton}
            />
            <skinnedMesh
              name="eyel"
              geometry={nodes.eyel.geometry}
              material={material}
              skeleton={nodes.eyel.skeleton}
            />
            <skinnedMesh
              name="eyer"
              geometry={nodes.eyer.geometry}
              material={material}
              skeleton={nodes.eyer.skeleton}
            />
            <skinnedMesh
              name="hair"
              geometry={nodes.hair.geometry}
              material={material}
              skeleton={nodes.hair.skeleton}
            />
                 <skinnedMesh
              name="limbs"
              geometry={nodes.limbs.geometry}
              material={material}
              skeleton={nodes.limbs.skeleton}
            />
            <skinnedMesh
              name="mouth"
              geometry={nodes.mouth.geometry}
              material={material}
              skeleton={nodes.mouth.skeleton}
            />


            



            <primitive object={nodes.mixamorigHips} />
          </group>



        </group>
     

        </Float>

          <group name="fiorearm1">
            <skinnedMesh
              name="fiore1"
              geometry={nodes.fiore1.geometry}
              material={material}
              skeleton={nodes.fiore1.skeleton}
              position={[
                nodes.fiore1.position.x,
                nodes.fiore1.position.y,
                nodes.fiore1.position.z
              ]}
            />
            <primitive object={nodes.Body5} />
          </group>
          <group name="fiorearm3">
            <skinnedMesh
              name="fiore3"
              geometry={nodes.fiore3.geometry}
              material={material}
              skeleton={nodes.fiore3.skeleton}
              position={[
                nodes.fiore3.position.x,
                nodes.fiore3.position.y,
                nodes.fiore3.position.z
              ]}
            />
            <primitive object={nodes.Body5_1} />
          </group>
          <group name="fiorearm2">
            <skinnedMesh
              name="fiore2"
              geometry={nodes.fiore2.geometry}
              material={material}
              skeleton={nodes.fiore2.skeleton}
              position={[
                nodes.fiore2.position.x,
                nodes.fiore2.position.y,
                nodes.fiore2.position.z
              ]}
            />
            <primitive object={nodes.Body5_2} />
          </group>
          <group name="fiorearm4">
            <skinnedMesh
              name="fiore4"
              geometry={nodes.fiore4.geometry}
              material={material}
              skeleton={nodes.fiore4.skeleton}
              position={[
                nodes.fiore4.position.x,
                nodes.fiore4.position.y,
                nodes.fiore4.position.z
              ]}
            />
            <primitive object={nodes.Body5_3} />
          </group>
          <group name="fiorearm5">
            <skinnedMesh
              name="fiore5"
              geometry={nodes.fiore5.geometry}
              material={material}
              skeleton={nodes.fiore5.skeleton}
              position={[
                nodes.fiore5.position.x,
                nodes.fiore5.position.y,
                nodes.fiore5.position.z
              ]}
            />
            <primitive object={nodes.Body5_4} />
          </group>
          <group name="fiorearm6">
            <skinnedMesh
              name="fiore6"
              geometry={nodes.fiore6.geometry}
              material={material}
              skeleton={nodes.fiore6.skeleton}
              position={[
                nodes.fiore6.position.x,
                nodes.fiore6.position.y,
                nodes.fiore6.position.z
              ]}
            />
            <primitive object={nodes.Body5_5} />
          </group>



          <mesh
          name="pianta1"
    
          geometry={nodes.pianta1.geometry}
          material={material}
        />
        <mesh
          name="pianta2"
 
          geometry={nodes.pianta2.geometry}
          material={material}
        />
        <mesh
          name="pianta3"
   
          geometry={nodes.pianta3.geometry}
          material={material}
        />
        <mesh
          name="pianta4"

          geometry={nodes.pianta4.geometry}
          material={material}
        />
        <mesh
          name="pianta5"
 
          geometry={nodes.pianta5.geometry}
          material={material}
        />
        <mesh
          name="pianta6"
 
          geometry={nodes.pianta6.geometry}
          material={material}
        />
      

        </group>

        <mesh rotation={[-Math.PI / 2.2, 0, 0]} position={[0, -2, -5]}>
        <circleGeometry args={[500, 50]} />
        <MeshReflectorMaterial
          // blur={[30, 30]}
          resolution={1024}
           mixBlur={0.4}
         mixStrength={10}
        //  depthScale={0.1}
          // minDepthThreshold={0.1}
          color="#666"
          metalness={0.8}
          roughness={1}
        />
      </mesh>


      </group>

  




      <Html
        wrapperClass="fullscreen"
      >

   
        <Modal
          id="end"
          ref={modalRefFinal}
          modal={"end"}
          continueF={resetAll}
        />
  

      </Html>
         
    
      <Postprocessing />  
          <CameraControlsBox ref={camera}/>
    </>
  );
}

