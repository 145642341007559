import Overlay from "../components/Overlay.jsx";
export default function ErrorPage(props) {
  document.title = props.title;

  document.body.classList.remove("w__shop");
  document.body.classList.remove("w__webmail");
  document.body.classList.remove("w__canvas");
  document.body.classList.remove("w__about");
  document.body.classList.add("w__error");
  document.getElementById("favicon1").href = "/favicons/favicon.ico";
  document.getElementById("favicon2").href = "/favicons/favicon-16x16.png";
  document.getElementById("favicon3").href = "/favicons/favicon-32x32.png";
  return (
    <>

     
<div className="px-3 pt-2 pb-5 text-center main__container">
<h1 className="display-4 mb-3">Error 404</h1>
        <h2>Game Over</h2>
      
        </div>
      
      <Overlay back={true} />


   </>
  );
}
