import React,{ useState } from "react";
import { Footer, Navbar } from "../components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { resetCart } from "../../../components/shop/redux/action";
import store from "../../../components/shop/redux/store";
import { useStore } from "../../../components/GameStore.jsx";

const Checkout = () => {
  const state = useSelector((state) => state.handleCart);
  const [validated, setValidated] = useState("false");

  const handleSubmit = (event) => {
    const form = event.currentTarget;


    if (form.checkValidity() === false) {
    

   
  
    }else{
      const input = document.getElementById("cc-code");
      const code = input.value;
if(code=="BC666" && state.length==1){


  if(state[0].id==1){

    useStore.setState({ p3: true });
    
  }  else if(state[0].id==2){

    useStore.setState({ p4: true });
    
  }else if(state[0].id==3){

    useStore.setState({ p1: true });
    
  }else if(state[0].id==4){

    useStore.setState({ p2: true });
    
  }
  
  

    useStore.setState({ shop: true });
    store.dispatch(resetCart());
    setValidated("true");
  }
  
  else if(code=="BC666" && state.length!=1){
    const error = document.getElementById("cc-error");
    error.classList.add("d-block");
  }
  
  else if(code.trim()==""){
    const error = document.getElementById("pfError");
    error.classList.add("d-block");
  }else{
    const error = document.getElementById("cc-error");
    error.classList.add("d-block");
  }



  }
      event.preventDefault();
      event.stopPropagation();
  };
  const handleChange = (event) => {
    const input = event.currentTarget;
    var errorMessage = input.parentNode.querySelector('.invalid-feedback');

  

    if (input.checkValidity() === false && !errorMessage.classList.contains("d-block")) {
    
      errorMessage.classList.add("d-block");
 
    } else if (errorMessage.classList.contains("d-block") && input.checkValidity() === true ){

      errorMessage.classList.remove("d-block");
  }


    

  };
  const handleInvalid = (event) => {
    const input = event.currentTarget;
    var errorMessage = input.parentNode.querySelector('.invalid-feedback');

    if (!errorMessage.classList.contains("d-block")) {
      errorMessage.classList.add("d-block");
    } 



    

  };
  const handlReset = (event) => {
    setValidated("false");
    window.open("/shop", "_self");

   
  };

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-secondary text-center">
            <h4 className="p-3 display-5">The shopping cart is empty</h4>
            <Link to="/shop" className="btn btn-primary mx-4">
              <i className="fa fa-arrow-left"></i> Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    );
  };
  const ThankYou = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-secondary text-center">
            <h4 className="p-3 display-5">Thank you</h4>
            <p>The ordered goods will be delivered as soon as possible.</p>
            <Link onClick={handlReset} className="btn btn-primary mx-4">
              <i className="fa fa-arrow-left"></i> Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    );
  };
  const ShowCheckout = () => {
    let subtotal = 0;
    let shipping = 9.90;
    let totalItems = 0;
    state.map((item) => {
      return (subtotal += item.price * item.qty);
    });

    state.map((item) => {
      return (totalItems += item.qty);
    });
    return (
      <>
        <div className="container pb-5 text-start">
          <div className="row my-4">




            <div className="col-md-5 col-lg-4 order-md-last">
              <div className="card mb-4">
                <div className="card-header py-3 bg-light">
                  <h5 className="mb-0">Order Summary</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Products ({totalItems})<span>CHF {subtotal.toFixed(2)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                     Shipping
                      <span>CHF {shipping.toFixed(2)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Total amount</strong>
                      </div>
                      <span>
                        <strong>CHF {(subtotal + shipping).toFixed(2)}</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8 ">
              <div className="card mb-4">
                <div className="card-header py-3">
                  <h4 className="mb-0">Billing address</h4>
                </div>
                <div className="card-body">
                  <form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-sm-6 my-1">
                        <label htmlFor="firstName" className="form-label">
                      First name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder=""
                          required
                          onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback">
                        Field is required.
                        </div>
                      </div>

                      <div className="col-sm-6 my-1">
                        <label htmlFor="lastName" className="form-label">
                Last name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          placeholder=""
                          required
                          onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback">
                        Field is required.
                        </div>
                      </div>

                      <div className="col-md-6 my-1">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder=""
                          required
                          onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback">
                        Please enter a valid email address.
                        </div>
                      </div>

                      <div className="col-md-6 my-1">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          placeholder=""
                          required
                   onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback">
                        Field is required.
                        </div>
                      </div>

                

             


                      <div className="col-md-6 my-1">
                        <label htmlFor="zip" className="form-label">
                          PLZ
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="zip"
                          placeholder=""
                          required
                           onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback">
                        Field is required.
                        </div>
                      </div>

                      <div className="col-md-6 my-1">
                        <label htmlFor="city" className="form-label">
                       City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          placeholder=""
                          required
                           onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback">
                        Field is required.
                        </div>
                      </div>


                    </div>

                    <hr className="my-4" />

                    <h4 className="mb-3">Payment</h4>

                    <div className="row gy-3">
                      <div className="col-md-6">
                        <label htmlFor="cc-name" className="form-label">
                        Name on card
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-name"
                          placeholder=""
                          required
                         onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                   
                        <div className="invalid-feedback">
                        Field is required.
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="cc-number" className="form-label">
                       Credit card number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-number"
                          placeholder=""
                          required
                          onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback" id="pfError">
                        Field is required.
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="cc-expiration" className="form-label">
                       Expiration
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-expiration"
                          placeholder=""
                          required
                          onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback">
                        Field is required.
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="cc-cvv" className="form-label">
                          CVV
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-cvv"
                          placeholder=""
                          required
                          onChange={handleChange}
                          onInvalid={handleInvalid}
                        />
                        <div className="invalid-feedback">
                        Field is required.
                        </div>
                      </div>
                    </div>

                    <hr className="my-4" />


                    <h4 className="mb-3">Voucher</h4>

<div className="row gy-3">
  

  <div className="col-12">
    <label htmlFor="cc-code" className="form-label">
      Code
    </label>
    <input
      type="text"
      className="form-control"
      id="cc-code"
      placeholder=""
      onChange={handleChange}
    />
    <div className="invalid-feedback" id="cc-error">
    Coupon is not valid.
    </div>
  </div>
</div>

<hr className="my-4" />
                    <button
                      className="w-100 btn btn-primary "
                      type="submit" 
                    >
                    Buy
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Navbar />
      <div className="container mt-5 mb-3 py-3 text-center">
   
        <h1 className="display-1 text-center text-primary mb-3">Checkout</h1>
         
    

         {validated === "true" ? <ThankYou /> : null}
         {validated === "false" && state.length ? <ShowCheckout /> : null}
         {validated === "false" && !state.length ? <EmptyCart /> : null}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
