import { useEffect } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { useStore } from "./components/GameStore.jsx";

import Root from "./routes/Root.jsx";
import ErrorPage from "./routes/ErrorPage.jsx";
import Webmail from "./routes/Webmail.jsx";
import Ecommerce from "./routes/Ecommerce.jsx";
import About from "./routes/About.jsx";


export default function Game() {
  const updateStore = () => {
    useStore.persist.rehydrate();
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", updateStore);
    window.addEventListener("focus", updateStore);
    return () => {
      document.removeEventListener("visibilitychange", updateStore);
      window.removeEventListener("focus", updateStore);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root title="Bönk Pixel Pandemonium" />,
      errorElement: <ErrorPage title="Bönk Error 404" />,
    },
    {
      path: "/about",
      element: <About title="About Bönk Pixel Pandemonium" />
    },
    {
      path: "/webmail",
      element: <Webmail title="Bönkmail" />,
    },
    {
      path: "/shop",
      element: <Ecommerce title="Scrub-a-Dub - Sparkle and Shine, Every Time!" router="Shop" />,
    },
    {
      path: "/shop/:id",
      element: <Ecommerce title="Scrub-a-Dub - Sparkle and Shine, Every Time!" router="Product" />,
    },
    {
      path: "/shop/cart",
      element: <Ecommerce title="Scrub-a-Dub - Sparkle and Shine, Every Time!" router="Cart" />,
    },
    {
      path: "/shop/checkout",
      element: <Ecommerce title="Scrub-a-Dub - Sparkle and Shine, Every Time!" router="Checkout" />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
