import  { createRef,Component } from "react";
import { InboxHtml } from "./templates/InboxHtml.jsx";
import ModalCompose from "./ModalCompose.jsx";
import ModalMessage from "./ModalMessage.jsx";
import Modal from "../Modal.jsx";
import { useStore } from "../GameStore.jsx";

export class Inbox extends Component {
  constructor(props) {
    super(props);
    this.markRead = this.markRead.bind(this);
    this.doShow = this.doShow.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.doError= this.doError.bind(this);
    this.toggleMark = this.toggleMark.bind(this);
    this.toggleMarkAll = this.toggleMarkAll.bind(this);
    this.deleteMarked = this.deleteMarked.bind(this);
    this.refreshMessages = this.refreshMessages.bind(this);
    this.deleteMessages = this.deleteMessages.bind(this);
    this.doCompose = this.doCompose.bind(this);

    this.ModalMessage = createRef();
 

    this.ModalCompose = createRef();


    this.ModalError1 = createRef();


    this.state = {
   
      messages: props.messages,
      selected: {}
    };

  }



  markRead(idx) {
    /* mark this message as read */
     //let messages = useStore((state) => state.messages);
     let messages = [...this.state.messages];
    messages[idx].read = true;
    this.setState({ messages });
     useStore.setState({
       messages: messages
     });


     const number = 
      messages.filter((v, k) => {
          return !v.read ;
        }).length
    
     if(number==0) {
   useStore.setState({ webmail: true });
  }
}
  doShow(idx) {
    let messages = [...this.state.messages];
    this.markRead(idx);
   
    this.setState({     selected: messages[idx] });


  this.ModalMessage.current.show();


  }

  doCompose() {

  this.ModalCompose.current.show();

  }

  doError() {
   
    this.ModalMessage.current.hide();
   this.ModalCompose.current.hide();
    this.ModalError1.current.show();

  
  }

  toggleMark(idx) {

    let messages = [...this.state.messages];
    messages[idx].marked = messages[idx].marked ? 0 : 1;
    this.setState({ messages });
;
  }

  doDelete(idx) {

     let messages = [...this.state.messages];
    messages[idx].deleted = 1;
    this.setState({ messages });
    useStore.setState({
      messages: messages
    });
  }

  toggleMarkAll() {

    let messages = [...this.state.messages];
    messages.map((v, k) => {
      return (v.marked = v.marked ? 0 : 1);
    });
    this.setState({ messages });

  }

  deleteMarked() {
    var self = this;

    let messages = [...this.state.messages];
    var tbd = [];
    for (var k = 0; k < messages.length; k++) {
      if (messages[k].marked === 1) {
        tbd.push(k);
      }
    }

    if (tbd.length > 0) {
      self.deleteMessages(tbd);
    }
  }

  refreshMessages() {

    this.ModalMessage.current.hide();
    this.ModalCompose.current.hide();
     this.ModalError1.current.show();



  }

  deleteMessages(arr) {
    //let messages = useStore((state) => state.messages);
    let messages = [...this.state.messages];

  
    for (var i = arr.length - 1; i >= 0; i--) {
      messages[i].deleted = 1;
 
    }
    this.setState({ messages });
    useStore.setState({
      messages: messages
    });
  }

  render() {


    return (
      <div>
        <InboxHtml parent={this} messages={this.state.messages}/>

      <ModalCompose parent={this} sendTo={this.state.selected.fromAddress} ref={this.ModalCompose} />
    
        <ModalMessage parent={this} ref={this.ModalMessage} message={this.state.selected} />
       
     
        <Modal id="e1" parent={this} ref={this.ModalError1} modal={"Keine Internet Verbindung"} />
      
      </div>
    );
  }
}

export default Inbox;
