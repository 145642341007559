import Overlay from "../components/Overlay.jsx";
export default function About(props) {
  document.title = props.title;
  document.body.classList.add("w__about");
  document.body.classList.remove("w__error");
  document.body.classList.remove("w__shop");
  document.body.classList.remove("w__webmail");
  document.body.classList.remove("w__canvas");



  document.getElementById("favicon1").href = "/favicons/favicon.ico";
  document.getElementById("favicon2").href = "/favicons/favicon-16x16.png";
  document.getElementById("favicon3").href = "/favicons/favicon-32x32.png";



  return (
    <>

     
<div className="px-3 pt-2 pb-5 text-center main__container">
<h1  className="display-1">Bönk</h1>
        <h2>Pixel Pandemonium</h2>



        <div className="lead">Bönk and Chon spend their days confined within a closed room, fully immersed in their digital world. However, one day, Bönk has a mystical experience and realizes he can no longer accept this situation. He attempts to escape with Chon, and this is where the game theoretically begins. Unfortunately, due to technical errors, system failures, and human oversights, this remains an early, incomplete prototype. We sincerely apologize for any inconvenience caused.</div>
        
        <div>Illustrations and Programming: Gelinda Paganini (<a href="https://gelinda.ch" target="_blank">www.gelinda.ch</a>).</div>

        <div>This project was supported by a grant from the Canton of Lucerne.</div>
        
        </div>
      
      <Overlay close={true} about={false} />
   </>
  );
}








