import { Html } from "@react-three/drei";

export default function LoadingScreen() {
  return (
    <>
      <color args={["#000000"]} attach="background" />
      <Html scale={0.3} center>
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-light " role="status"></div>
        </div>
      </Html>
    </>
  );
}
