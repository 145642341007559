import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import Draggable from "react-draggable";
import useSound from "use-sound";
import click01 from "/sound/click01.wav";
import click02 from "/sound/click02.wav";

const ModalCompose = forwardRef((props, ref) => {
  const [playClick01] = useSound(click01);
  const [playClick02] = useSound(click02);

  useImperativeHandle(ref, () => ({
    show() {
      showF();
    },
    hide() {
      hideF();
    },
  }));

  useEffect(() => {
    const handleResize = () => {
      const modalRect = document.querySelector("#modalCompose .modal-content");
      const rootRect = document.querySelector("#root").getBoundingClientRect();

      const boundLN =
        rootRect.left - rootRect.width / 2 + modalRect.scrollWidth / 2 + 1;
      const boundTN =
        rootRect.top - rootRect.height / 2 + modalRect.scrollHeight / 2 + 1;
      const boundRN =
        rootRect.right - rootRect.width / 2 - modalRect.scrollWidth / 2 - 1;
      const boundBN =
        rootRect.bottom - rootRect.height / 2 - modalRect.scrollHeight / 2 - 1;

      setState({
        ...state,
        boundL: boundLN,
        boundT: boundTN,
        boundR: boundRN,
        boundB: boundBN,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [state, setState] = useState({
    draggerStart: null,
    boundL: null,
    boundT: null,
    boundR: null,
    boundB: null,
  });

  const draggerRef = React.createRef();

  const updateSize = () => {
    const modalRect = document.querySelector("#modalCompose .modal-content");
    const rootRect = document.querySelector("#root").getBoundingClientRect();

    const boundLN =
      rootRect.left - rootRect.width / 2 + modalRect.scrollWidth / 2 + 1;
    const boundTN =
      rootRect.top - rootRect.height / 2 + modalRect.scrollHeight / 2 + 1;
    const boundRN =
      rootRect.right - rootRect.width / 2 - modalRect.scrollWidth / 2 - 1;
    const boundBN =
      rootRect.bottom - rootRect.height / 2 - modalRect.scrollHeight / 2 - 1;

    draggerRef.current.state.x = 0;
    draggerRef.current.state.y = 0;

    setState({
      draggerStart: { x: 0, y: 0 },
      boundL: boundLN,
      boundT: boundTN,
      boundR: boundRN,
      boundB: boundBN,
    });
  };

  const startDrag = () => {
    setState({ ...state, draggerStart: null });
  };

  const showF = () => {

  
     
  

    var modalObject = bootstrap.Modal.getOrCreateInstance("#modalCompose", {
      backdrop: true,
    });
    modalObject.show();
    document.querySelector("#modalCompose").classList.add("ontop");


    updateSize();
  };

  const hideF = () => {
    var modalObject = bootstrap.Modal.getOrCreateInstance("#modalCompose", {
      backdrop: true,
    });
    modalObject.hide();
  };

  return (
    <div
      id="modalCompose"
      className="modal fade ontop"
      tabIndex="-1"
      role="dialog"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
     
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <Draggable
            handle=".modal-header"
            defaultPosition={{ x: 0, y: 0 }}
            bounds={{
              left: state.boundL,
              top: state.boundT,
              right: state.boundR,
              bottom: state.boundB,
            }}
            position={state.draggerStart}
            ref={draggerRef}
            onStart={startDrag}
          >
            <div className="modal-content">
           
                <div className="modal-header">
                  <h5  className="modal-title">Compose</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form className="form" role="form" autoComplete="off">
                    <div className="form-row py-2">
                      <label htmlFor="sendTo" className="col-sm-2 mb-0">
                        To
                      </label>
                      <div className="col">
                        <input
                          type="text"
                          name="sendTo"
                          id="sendTo"
                          className="form-control"
                          required=""
                          value={props.sendTo}
                        />
                      </div>
                    </div>
                    <div className="form-row py-2">
                      <label htmlFor="subject" className="col-sm-2 mb-0">
                        Subject
                      </label>
                      <div className="col">
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          className="form-control"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="form-row py-2">
                      <label htmlFor="message2" className="col-sm-2 mb-0">
                        Message
                      </label>
                      <div className="col">
                        <textarea
                          rows="6"
                          name="message2"
                          id="message2"
                          className="form-control"
                          required=""
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    onClick={(e) => {
                      props.parent.doError();
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                   Save Draft
                  </button>

                  <button
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                    onClick={(e) => {
                      props.parent.doError();
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                   Send
                  </button>
                </div>
           
            </div>
          </Draggable>
        </div>
      </div>
 
  );
});

export default ModalCompose;
