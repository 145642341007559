import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import Draggable from "react-draggable";
import useSound from "use-sound";
import click01 from "/sound/click01.wav";
import click02 from "/sound/click02.wav";

const ModalMessage = forwardRef((props, ref) => {
  const [playClick01] = useSound(click01);
  const [playClick02] = useSound(click02);

  useImperativeHandle(ref, () => ({
    show() {
      showF();
    },
    hide() {
      hideF();
    },
  }));

  useEffect(() => {
    const handleResize = () => {
      const modalRect = document.querySelector("#messageModal .modal-content");
      const rootRect = document.querySelector("#root").getBoundingClientRect();

      const boundLN =
        rootRect.left - rootRect.width / 2 + modalRect.scrollWidth / 2 + 1;
      const boundTN =
        rootRect.top - rootRect.height / 2 + modalRect.scrollHeight / 2 + 1;
      const boundRN =
        rootRect.right - rootRect.width / 2 - modalRect.scrollWidth / 2 - 1;
      const boundBN =
        rootRect.bottom - rootRect.height / 2 - modalRect.scrollHeight / 2 - 1;

      setState({
        ...state,
        boundL: boundLN,
        boundT: boundTN,
        boundR: boundRN,
        boundB: boundBN,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [state, setState] = useState({
    draggerStart: null,
    boundL: null,
    boundT: null,
    boundR: null,
    boundB: null,
  });

  const draggerRef = React.createRef();

  const updateSize = () => {
    const modalRect = document.querySelector("#messageModal .modal-content");
    const rootRect = document.querySelector("#root").getBoundingClientRect();

    const boundLN =
      rootRect.left - rootRect.width / 2 + modalRect.scrollWidth / 2 + 1;
    const boundTN =
      rootRect.top - rootRect.height / 2 + modalRect.scrollHeight / 2 + 1;
    const boundRN =
      rootRect.right - rootRect.width / 2 - modalRect.scrollWidth / 2 - 1;
    const boundBN =
      rootRect.bottom - rootRect.height / 2 - modalRect.scrollHeight / 2 - 1;

    draggerRef.current.state.x = 0;
    draggerRef.current.state.y = 0;

    setState({
      draggerStart: { x: 0, y: 0 },
      boundL: boundLN,
      boundT: boundTN,
      boundR: boundRN,
      boundB: boundBN,
    });
  };

  const startDrag = () => {
    setState({ ...state, draggerStart: null });
  };

  const showF = () => {

 
     



    var modalObject = bootstrap.Modal.getOrCreateInstance("#messageModal", {
      backdrop: true,
    });
    modalObject.show();
    document.querySelector("#messageModal").classList.add("ontop");

    updateSize();
  };

  const hideF = () => {
    var modalObject = bootstrap.Modal.getOrCreateInstance("#messageModal", {
      backdrop: true,
    });
    modalObject.hide();
  };

  return (
    <div
      id="messageModal"
      className="modal fade ontop"
      tabIndex="-1"
      role="dialog"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
   
        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <Draggable
            handle=".modal-header"
            defaultPosition={{ x: 0, y: 0 }}
            bounds={{
              left: state.boundL,
              top: state.boundT,
              right: state.boundR,
              bottom: state.boundB,
            }}
            position={state.draggerStart}
            ref={draggerRef}
            onStart={startDrag}
          >
            <div className="modal-content">
          
                <div className="modal-header">
                <div>
                <small  className="modal-title">Subject</small>
                <h4 className="modal-title">{props.message.subject || ""}</h4>
              </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                <div className="row">
                <div className="col-sm-8">
                  <small className="text-uppercase text-muted">From</small>
                  <h4>
               
                      {props.message.from || ""}
                
                  </h4>
                </div>
                <div className="col-sm-4">
                  <small className="text-uppercase text-muted">Sent</small>
                  <h6>{props.message.dtSent || ""}</h6>
                </div>
                <div className="col-sm-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: props.message.body || ""
                    }}
                  />

{props.message.img ?
 ( <a href="#" onClick={() => window.open("/shop", "_blank")} >
                              <img src={"/images/webmail/"+props.message.img} width={props.message.imgW+"px"} /> </a> )
                       :null}

                </div>
              </div>


          
                </div>
                <div className="modal-footer">
                <button className="btn btn-secondary float-right ms-2"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
            Close
              </button>
              <button
                className="btn btn-primary float-right"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#modalCompose"
              >
               Reply
              </button>
                </div>
            
            </div>
          </Draggable>
        </div>
      </div>
 
  );
});

export default ModalMessage;
