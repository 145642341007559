import { useStore } from "../components/GameStore.jsx";
import {createRef } from "react";
import  Modal  from "../components/Modal.jsx";
export default function Overlay(prop) {

  const modalRef = createRef();


  // const shop = useStore((state) => state.shop);
  // const webmail = useStore((state) => state.webmail);
  const reset = useStore((state) => state.reset);
  const resetAll = () => {
    reset();
 
 
  };
  const resetGame = (e) => {

    modalRef.current.show()
    e.stopPropagation()
  };
  const closeTab = (e) => {
    window.opener = null;
    window.open("", "_self");
    window.close();
    e.stopPropagation()
  };
  // const openWebmail = (e) => {
  //   window.open("/webmail", "_blank");
  //   e.stopPropagation()
  // };
  // const openShop = (e) => {
  //   window.open("/shop", "_blank");
  //   e.stopPropagation()
  // };
  const openAbout = (e) => {
    window.open("/about", "_blank");
    e.stopPropagation()
  };
  const goBack = (e) => {
    window.open("/", "_self");
    e.stopPropagation()
  };
  return (
    <>
      <div className="overlay__container position-fixed bottom-0 end-0 d-flex justify-content-between py-1 px-2 w-100 bg-dark text-light border-top">

      <div className="d-flex">
      <div>
        <a href="/">
        <span className="text-light cube">Bönk</span><br/><span className="small text-secondary">Prototype 0.0.1.</span></a>
        </div>

          </div>
          
          <div>
  
        


        {prop.close === false && prop.back !== true  ? (
          <button onClick={resetGame} type="button" className="btn btn-primary ms-2">
            <i className="fa-solid fa-repeat"></i> Restart
          </button>
        ) : null}
  

        {/* {webmail === false && prop.close === false && prop.back !== true ? (
          <button onClick={openWebmail} type="button" className="btn btn-outline-light ms-2">
            <i className="fa-regular fa-envelope"></i> Webmail
          </button>
        ) : null}
        {shop === false && prop.close === false && prop.back !== true ? (
          <button onClick={openShop} type="button" className="btn btn-outline-light ms-2">
            <i className="fa-solid fa-bag-shopping"></i> Shop
          </button>
        ) : null} */}
        {prop.close === true && prop.back !== true && (window.opener != null ) ? (
          <button onClick={closeTab} type="button" className="btn btn-outline-light ms-2">
            <i className="fa-solid fa-xmark"></i> Close
          </button>
        ) : null}
              {prop.back === true || (prop.close === true && prop.back !== true && (window.opener == null )) ? (
          <button onClick={goBack} type="button" className="btn btn-outline-light ms-2">
            <i className="fa-solid fa-arrow-left-long"></i>Back
          </button>
        ) : null}
      
      {prop.about !== false ? (
      <button onClick={openAbout} type="button" className="btn btn-outline-light ms-2">
        <i className="fa-solid fa-skull"></i> About
          </button>

) : null}
           </div>
      </div>

      <Modal
   id="confirm"
   ref={modalRef}
        modal={"confirm"}
        continueF={resetAll}
      />  

    </>
  );
}
