import {
    EffectComposer,
    Bloom,
    HueSaturation,
    BrightnessContrast,
    TiltShift2,TiltShift,
    WaterEffect,
    ToneMapping,  DepthOfField,
    Pixelation,
    Noise,
    Vignette,ChromaticAberration,LensFlare,Scanline
  } from "@react-three/postprocessing";
  import { BlendFunction } from 'postprocessing'
  import { Glitch } from '@react-three/postprocessing'
import { GlitchMode } from 'postprocessing'
import VideoBackground from "../components/VideoBackground.jsx";
import { Preload, AdaptiveDpr, Bvh, Billboard, Environment, AdaptiveEvents } from "@react-three/drei";
export default function Postprocessing02() {

    return (
      <>

<ambientLight intensity={2} />
    
         <EffectComposer disableNormalPass   depthBuffer> 

  

         <Scanline density={1} 
         
        //  blendFunction={BlendFunction.HARD_LIGHT} 
         
         opacity={0.1}/>
         {/* <Glitch
    delay={[0, 1]} // min and max glitch delay
    duration={[0.6, 1.0]} // min and max glitch duration
    strength={[0.001, 0.001]} // min and max glitch strength
    mode={GlitchMode.CONSTANT_MILD} // glitch mode
  //  active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
   // ratio={1} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.

   columns={0.0001}
  /> */}
         <Pixelation
    granularity={2.5} 
  />  
  
   <ChromaticAberration
  blendFunction={BlendFunction.NORMAL} // blend mode
  offset={[0.005, 0.01] } // color offset

radialModulation 
 modulationOffset = {0.0000000001}

/> 
            {/* <HueSaturation saturation={-1} />  */}
        <BrightnessContrast brightness={0} contrast={0.2} />       
           {/* <WaterEffect factor={0.3} />  */}
           {/* <TiltShift2 samples={100} blur={0.2} /> */}

     
    

           <Bloom mipmapBlur luminanceThreshold={0.1} intensity={0.3} />     

{/* 
 <DepthOfField
          focusDistance={0}
          focalLength={0.2}
          bokehScale={4}
          height={180}
        />    
        {/* <Bloom
          luminanceThreshold={0}
          luminanceSmoothing={0.9}
          height={100}
          opacity={3}
        />  */}
         <Noise opacity={0.1}    
         //  blendFunction={BlendFunction.SCREEN} 
            />  
         <Vignette eskil={false} offset={0.1} darkness={1.1} />  
         </EffectComposer>
        </>
      );
    
}