import { useThree } from "@react-three/fiber";
import { forwardRef, useImperativeHandle, useState, useRef,useEffect } from "react";
import { Plane, CameraControls } from "@react-three/drei";

const CameraControlsBox01 = forwardRef((props, ref) => {
  const meshRef = useRef();
  const cameraControlsRef = useRef();

  const { camera, size } = useThree();
  camera.position.x = 0;
  camera.position.y = 0;
  camera.position.z = 7;

  const init = {
    zoomElement: meshRef,
    cover: false,
    first: false,
    events: true,
  };

  const [state, setState] = useState(init);

  useImperativeHandle(ref, () => ({
    zoom(element, cover) {
      zoomF(element, cover);
    },
    clear() {
      initF();
    },
  }));

  const zoomF = (element, cover) => {
    cameraControlsRef.current.rotateTo(0, Math.PI * 0.5, true);

    setState({
      ...state,
      zoomElement: element,
      cover: cover,
      first: false,
      events: false,
    });
  };

  const initF = () => {
    setState({
      ...state,
      zoomElement: meshRef,
      cover: false,
      first: false,
      events: true,
    });
  };
  //const cameraOnChange = () => {
  // console.log(cameraControlsRef.current._zoom)
  //};


  let customsize = [6, 10];

  if ( size.width > 800) {
    customsize = [5, 7];
  }
  if (size.width > 1000) {
    customsize = [4, 5];
  }

  if (size.width > 1400) {
    customsize = [3, 4];
  }


  if (state.zoomElement && state.zoomElement.current && state.first == false) {
    setState({ ...state, first: true });

    cameraControlsRef.current.fitToBox(state.zoomElement.current, true, {
      cover: state.cover,
    });

    if (state.events == false) {
      cameraControlsRef.current.mouseButtons.left = 0;
      cameraControlsRef.current.mouseButtons.wheel = 0;
      cameraControlsRef.current.touches.one = 0;
      cameraControlsRef.current.touches.two = 0;
    } else {
      cameraControlsRef.current.mouseButtons.left = 1;
      cameraControlsRef.current.mouseButtons.wheel = 8;
      cameraControlsRef.current.touches.one = 32;
      cameraControlsRef.current.touches.two = 1024;
    }
    cameraControlsRef.current.mouseButtons.right = 0;
    cameraControlsRef.current.mouseButtons.middle = 0;
    cameraControlsRef.current.touches.three = 0;
    cameraControlsRef.current.minDistance =1;
    cameraControlsRef.current.maxDistance = 10;


    cameraControlsRef.current.maxAzimuthAngle = 0.5; // LEFT RIGHT
    cameraControlsRef.current.minAzimuthAngle = -0.5;
  cameraControlsRef.current.maxPolarAngle = Math.PI / 2 + 0.5; // TOP Bottom
  cameraControlsRef.current.minPolarAngle = Math.PI / 2 - 0.5;


  
  }



useEffect(() => {
    function handleResize() {
      setState({ ...state, first: false });
    }

    window.addEventListener('resize', handleResize)
  })

  return (
    <>
      <CameraControls
        ref={cameraControlsRef}
        // onChange={cameraOnChange}
        makeDefault
      />

      <Plane
        ref={meshRef}
        args={customsize}
        material-color="red"
        position={[0, 0, 0]}
        visible={false}
      />
    </>
  );
});

export default CameraControlsBox01;
