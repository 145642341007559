import { useStore } from "../components/GameStore.jsx";
import Inbox from "../components/webmail/Inbox";

import Overlay from "../components/Overlay.jsx";
export default function Webmail(props) {
  document.title = props.title;
  document.body.classList.remove("w__canvas");
  document.body.classList.remove("w__shop");
  document.body.classList.remove("w__about");
  document.body.classList.remove("w__error");
  document.body.classList.add("w__webmail");

  document.getElementById("favicon1").href = "/favicons/webmail/favicon.ico";
  document.getElementById("favicon2").href = "/favicons/webmail/favicon-16x16.png";
  document.getElementById("favicon3").href = "/favicons/webmail/favicon-32x32.png";


 
  const messages = useStore((state) => state.messages);
  return (
    <>
      <div>
   
        <div className="container">
          <Inbox messages={messages}/>
        </div>
     
      </div>

      <Overlay close={true} />
    </>
  );
}
