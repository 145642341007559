// For Add Item to Cart
export const addCart = (product) =>{
    return {
        type:"ADDITEM",
        payload:product
    }
}

// For Delete Item to Cart
export const delCart = (product) =>{
    return {
        type:"DELITEM",
        payload:product
    }
}



export const loadCart = (product) =>{

    return {
        type:"LOAD",
        payload:product
    }
}



export const resetCart = (product) =>{

    return {
        type:"RESET",
        payload:product
    }
}