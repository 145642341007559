import {
    EffectComposer,
    Bloom,
    HueSaturation,
    BrightnessContrast,
    TiltShift2,TiltShift,
    WaterEffect,
    ToneMapping,  DepthOfField,
    Pixelation,
    Noise,
    Vignette,SSAO
  } from "@react-three/postprocessing";
  import { BlendFunction } from 'postprocessing'
  import VideoBackground from "../components/VideoBackground.jsx";
  import { Preload, AdaptiveDpr, Bvh, Billboard, Environment, AdaptiveEvents } from "@react-three/drei";
export default function Postprocessing01() {

    return (
      <>


{/* <ambientLight intensity={0} /> */}
<Environment
        files='kiara_1_dawn_1k2.hdr'
        path="../models/"
            background={true}
           backgroundBlurriness={0.2}
           backgroundIntensity={0.15}

            // files='kiara_1_dawn_1k.hdr'
            // path="../models/"
          /> 
         {/* <Billboard>
            <VideoBackground
              distance={100}
              intensity={30}
              angle={0.3}
              penumbra={1}
              position={[0, 0, 20]}
            />
          </Billboard>  */}


<EffectComposer depthBuffer enableNormalPass> 
{/*    <EffectComposer disableNormalPass>  */}


{/*          <SSAO
    blendFunction={BlendFunction.MULTIPLY} // blend mode
    samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
    rings={4} // amount of rings in the occlusion sampling pattern
    distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
    distanceFalloff={0.0} // distance falloff. min: 0, max: 1
    rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
    rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
    luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
    radius={20} // occlusion sampling radius
    scale={0.5} // scale of the ambient occlusion
    bias={0.5} // occlusion bias
  />  */}
    
    <Bloom mipmapBlur luminanceThreshold={0.8} intensity={2} />    

    <ToneMapping
    blendFunction={BlendFunction.NORMAL} // blend mode
    adaptive={true} // toggle adaptive luminance map usage
    resolution={256} // texture resolution of the luminance map
    middleGrey={0.6} // middle grey factor
    maxLuminance={16.0} // maximum luminance
    averageLuminance={1.0} // average luminance
    adaptationRate={1.0} // luminance adaptation rate
  />

          {/* <Pixelation
    granularity={2} 
  /> */}
            {/* <HueSaturation saturation={-1} />  */}
  {/* <BrightnessContrast brightness={0.1} contrast={0.1} />      */}
           {/* <WaterEffect factor={0.3} />  */}
           {/* <TiltShift2 samples={100} blur={0.2} /> */}

           {/* {scene === "TitleScene" ?  <WaterEffect factor={0.3} />     : null}  */}
       {/* {scene === "TitleScene" ?   <TiltShift2 samples={6} blur={0.2} />     : null}  
        */}
    

          

{/* 
 <DepthOfField
          focusDistance={0}
          focalLength={0.2}
          bokehScale={4}
          height={180}
        />    
        {/* <Bloom
          luminanceThreshold={0}
          luminanceSmoothing={0.9}
          height={100}
          opacity={3}
        />  */}
         <Noise opacity={0.05} />  
         <Vignette eskil={false} offset={0.1} darkness={1.1} /> 
         </EffectComposer>
        </>
      );
    
}