import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";

import { Footer, Navbar } from "../components";

import products from "../../../data/products.json";


const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
  };

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      setLoading2(true);
      //const response = await fetch(`https://fakestoreapi.com/products/${id}`);
      const data = products.find((d) => d.id == id);
    
      setProduct(data);
      setLoading(false);
  
      // const response2 = await fetch(
      //   `https://fakestoreapi.com/products/category/${data.category}`
      // );
    const data2 = products.filter((d) => d.id != id);

      setSimilarProducts(data2);
       setLoading2(false);
    };
    getProduct();
  }, [id]);

  const Loading = () => {
    return (
      <>
        <div className="container my-5 py-2 bg-secondary">
          <div className="row">
            <div className="col-md-6 py-3">
              <Skeleton height={400} width={400} />
            </div>
            <div className="col-md-6 py-5">
              <Skeleton height={30} width={250} />
              <Skeleton height={90} />
              <Skeleton height={40} width={70} />
              <Skeleton height={50} width={110} />
              <Skeleton height={120} />
              <Skeleton height={40} width={110} inline={true} />
              <Skeleton className="mx-3" height={40} width={110} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowProduct = () => {
    return (
      <>
        <div className="container my-3  bg-secondary p-3">
          <div className="row g-4">
            <div className="col-md-6 col-sm-12 py-3">
              <img
                className="img-fluid"
                src={product.image}
                alt={product.title}
                width="100%"
              />
            </div>
            <div className="col-md-6 col-md-6 py-5">
              <h4 className="text-uppercase text-muted">{product.category}</h4>
              <h1 className="display-5">{product.title}</h1>
              <p className="lead">
                {product.rating && product.rating.rate}{" "}
                <i className="fa fa-star"></i>
              </p>
              <h3 className="display-6  my-4">CHF { product.price ? (product.price).toFixed(2) : null}</h3>
              <p className="lead">{product.description}</p>
              <button
                className="btn btn-primary"
                onClick={() => addProduct(product)}
              >
              Add to Cart
              </button>
              <Link to="/shop/cart" className="btn btn-dark mx-3">
            Go to Cart
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };

  const Loading2 = () => {
    return (
      <>
        <div className="my-4 py-4">
          <div className="d-flex">
            <div className="mx-4">
              <Skeleton height={200} width={150} />
            </div>
            <div className="mx-4">
              <Skeleton height={200} width={150} />
            </div>
            <div className="mx-4">
              <Skeleton height={200} width={150} />
            </div>
            <div className="mx-4">
              <Skeleton height={200} width={150} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowSimilarProduct = () => {
    return (
      <>
        <div className="py-4 mb-4">
          <div className="d-flex">
            {similarProducts.map((item) => {
              return (
                <div key={item.id} className="card mx-2 text-center">
                  <img
                    className=""
                    src={item.image}
                    alt="Card"
                    height={"auto"}
                    width={"450px"}
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {item.title.substring(0, 15)}...
                    </h5>
                  </div>
                  {/* <ul className="list-group list-group-flush">
                    <li className="list-group-item lead">${product.price}</li>
                  </ul> */}
                  <div className="card-body">
                    <Link
                      to={"/shop/" + item.id}
                      className="btn btn-dark m-1"
                    >
                View
                    </Link>
                    <button
                      className="btn btn-dark m-1"
                      onClick={() => addProduct(item)}
                    >
              Add to Cart
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Navbar />
      <div className="container">
      <div className="row">
          <div className="col-12 text-center">
          <div> <img className="mt-5 mt-lg-3 mb-4 shop__logo" src="/images/shop/Scrub-a-Dub.png" width={"400px"} height={"auto"}/> </div>
      
          </div>
        </div>
        <div className="row">{loading ? <Loading /> : <ShowProduct />}</div>
        <div className="row mt-5">
          <div className="d-none d-md-block text-center">
          <h2 className="bg-primary d-inline-block px-1">You might also like this</h2>
            <Marquee
              pauseOnHover={false}
              pauseOnClick={false}
              speed={50}
            >
              {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
            </Marquee>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
